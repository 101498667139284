import I18n from 'i18n-js';

import en from "@app/core/locale/en.json";
import de from '@app/core/locale/de.json';
import ko from '@app/core/locale/ko.json';
import ja from '@app/core/locale/ja.json';
import es from '@app/core/locale/es.json';
import fr from '@app/core/locale/fr.json';
import zh from '@app/core/locale/zh.json';

export const SetLanguage = (lang: string) =>
{
    I18n.reset();
    I18n.locale = lang;
    I18n.fallbacks = true;
    I18n.translations = { en, ko, ja, de, es, fr, zh };
    localStorage.setItem("language", lang);
}
export const GetPreferred = () =>
{
    let saved = localStorage.getItem("language");
    let language = navigator.language || navigator['userLanguage'];
    return (!saved) ? language : saved;
}

export const ToError = (s: string) =>
{
    var regx = new RegExp(/\[(.*?)\]/g);
    let match = `${s}`.match(regx);
    let error = (!match || match?.length <= 0) ? "unknown" : match[0].toLowerCase().replace("[", "").replace("]", "");
    return I18n.t(`error.${error}`)
}
export const ToFixed = (value) =>
{
    if (!value) return null;
    return value.toFixed(2).replace(".00", "");
}
export const ToCurrency = (value, currency) =>
{
    return `£${value.toFixed(2)}`;
}

export const ToDaysAgo = (value) =>
{
    const date = new Date(value);
    const deltaDays = (date.getTime() - Date.now()) / (1000 * 3600 * 24);
    const formatter = new Intl.RelativeTimeFormat(I18n.locale);

    if (deltaDays > -30)
        return formatter.format(Math.round(deltaDays), 'days');
    if (deltaDays > -365)
        return formatter.format(Math.round(deltaDays / 30), 'months');

    return formatter.format(Math.round(deltaDays / 365), 'years');
}
export const ToDay = (timestamp) =>
{
    let date = new Date(timestamp);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let d = date.getDate();
    let m = months[date.getMonth()];
    return `${m} ${(d <= 9 ? '0' + d : d)}`;
}

SetLanguage(GetPreferred());
export { I18n, en };