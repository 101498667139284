import '@app/core/elements/Footer.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { I18n, SetLanguage } from '@app/core';
import { Update } from '@app/store/reducers/data.reducer';

interface Props { className?: string, styles?: any }
export const Footer: React.FC<Props> = ({ className, styles }) =>
{

    const dispatch = useDispatch();
    const year = new Date().getFullYear();

    const [IsDark, setIsDark] = useState(false);

    useEffect(() =>
    {
        let mode = document.querySelector("html").classList.contains("dark") == true;
        setIsDark(mode);
    }, []);

    const HandleDark = () =>
    {
        document.querySelector("html")?.classList.toggle("dark");
        let mode = document.querySelector("html").classList.contains("dark") == true;
        setIsDark(mode);
    };

    const HandleLang = (e) =>
    {
        SetLanguage(e.target.value);
        dispatch(Update({ landing: I18n.t("defaults.landing") }));
    }

    return (
        <footer className="p-4 bg-gray-50 sm:p-6 dark:bg-gray-800">
            <div className="mx-auto max-w-screen-xl">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <a href="https://twistedtamarin.com" className="flex items-center">
                            <div className="w-16 h-16"><div className="branding"></div></div>
                        </a>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{I18n.t("elem.footer.follow")}</h2>
                            <ul className="text-gray-600 dark:text-gray-400">
                                <li><a href="#" className="hover:underline">tiktok</a></li>
                                <li><a href="#" className="hover:underline">twitch</a></li>
                                <li><a href="https://discord.gg/FSDgeTqrJ8" className="hover:underline">discord</a></li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{I18n.t("elem.footer.resources")}</h2>
                            <ul className="text-gray-600 dark:text-gray-400">
                                <li><a href="/docs" className="hover:underline">{I18n.t("elem.footer.docs")}</a></li>
                                <li><a href="/about" className="hover:underline">{I18n.t("elem.footer.about")}</a></li>
                                <li><a href="/developers" className="hover:underline">{I18n.t("elem.footer.developers")}</a></li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{I18n.t("elem.footer.legal")}</h2>
                            <ul className="text-gray-600 dark:text-gray-400">
                                <li><a href="/privacy/twistedtamarin.com" className="hover:underline">{I18n.t("elem.footer.privacy")}</a></li>
                                <li><a href="/eula/twistedtamarin.com" className="hover:underline">{I18n.t("elem.footer.terms")}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="flex text-sm text-gray-500 sm:text-center dark:text-gray-400"> © {year} <a href="https://twistedtamarin.com" className="mx-2 hover:underline">twistedtamarin ltd.</a> {I18n.t("elem.footer.rights")} <span className="mx-2">{I18n.t("elem.footer.powered")} <a href="https://asari.cloud" target="_blank" className="hover:underline">asari</a></span></div>
                    <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white"><i className='fa-brands fa-github'></i></a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:fill-gray-500"><i className='fa-brands fa-tiktok'></i></a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:fill-gray-500"><i className='fa-brands fa-twitch'></i></a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:fill-gray-500"><i className='fa-brands fa-discord'></i></a>
                    </div>
                    <div className=''>
                        <select className="field text-sm px-2 py-1 rounded-md" onChange={HandleLang}>
                            <option value="en" selected>EN</option>
                            <option value="de">DE</option>
                            <option value="es">ES</option>
                            <option value="fr">FR</option>
                            <option value="ko">KO</option>
                            <option value="ja">JA</option>
                            <option value="zh">ZH</option>
                        </select>
                    </div>
                </div>
            </div>
        </footer>
    );
};