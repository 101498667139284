import '@app/screens/Home/Views/Landing.scss';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { SetLanguage, I18n } from '@app/core';
import { Asari } from '@app/services';
import { Fetch, Update } from '@app/store/reducers/data.reducer';
import { selectLanding, selectPage } from '@app/store/selectors/data.selectors';

import { Button, Footer } from '@app/core/elements';
import { ContactView } from '@app/screens/Home/Elements/Contact';

interface Props { }
export const LandingView: React.FC<Props> = () =>
{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { language } = useParams();
    let content = useSelector(selectLanding);

    const [cover, setCover] = useState(0);
    const hours = (new Date().valueOf() - new Date("2005.01.01").valueOf()) / 1000 / 60 / 60;
    const stats = { project: 14, customer: 432 }

    useEffect(() =>
    {
        SetLanguage((!!language) ? language : 'en');
        dispatch(Update({ landing: I18n.t("defaults.landing") }));
    }, []);

    const HandleScroll = async (e) =>
    {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.blur();

        let el = document.querySelector(e.currentTarget.pathname.replace("/", "#"));
        await el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        return;
    }

    return (
        <main className="min-w-full min-h-screen font-sans bg-white">

            <Helmet>
                <title>{content?.seo?.title}</title>
                <meta name='description' content={content?.seo?.description} />
                <meta property="og:type" content={content?.seo?.type} />
                <meta property="og:image" content={content?.seo?.image} />
                <meta property="og:title" content={content?.seo?.title} />
                <meta property="og:description" content={content?.seo?.description} />
            </Helmet>

            <header className="max-w-screen-xl mx-auto text-gray-600 body-font">
                <div className="flex flex-row justify-between px-5 py-4">
                    <a href="https://twistedtamarin.com" className="flex items-center">
                        <div className="hidden w-32 h-12 md:block"><div className="branding-long"></div></div>
                        <div className="w-12 h-12 md:hidden"><div className="branding"></div></div>
                    </a>
                    <nav className="flex flex-wrap items-center justify-center">
                        <a href="projects" className="mr-5 cursor-pointer hover:text-gray-900" onClick={HandleScroll}>{I18n.t("elem.header.projects")}</a>
                        <a href="about" className="mr-5 cursor-pointer hover:text-gray-900" onClick={HandleScroll}>{I18n.t("elem.header.about")}</a>
                        <a href="contact" className="mr-5 cursor-pointer hover:text-gray-900" onClick={HandleScroll}>{I18n.t("elem.header.contact")}</a>
                    </nav>
                </div>
            </header>

            <section className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                <div className="flex flex-col items-center px-5 py-24 md:flex-row">
                    <div className="w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0">
                        <img className="object-contain object-center rounded" alt="hero" src={content?.hero?.imageUrl} />
                    </div>
                    <div className="flex flex-col items-center text-center lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 md:items-start md:text-left">
                        <h1 className="mb-4 text-4xl font-medium text-gray-900 title-font sm:text-4xl">{content?.hero?.title}</h1>
                        <p className="mb-8 leading-relaxed">{content?.hero?.short}</p>
                        <div className="flex justify-center">
                            <Button className="primary" onClick={async () =>
                            {
                                let el = document.querySelector("#contact");
                                await el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                            }}>{content?.hero?.buttonA}</Button>
                            <Button className="ml-4 normal" onClick={async () =>
                            {
                                let el = document.querySelector("#projects");
                                await el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                            }}>{content?.hero?.buttonB}</Button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                <div className="container flex flex-wrap px-5 py-24 mx-auto">
                    <div className="flex flex-wrap content-start mt-auto mb-auto -mx-4 lg:w-1/2 sm:w-2/3 sm:pr-10">
                        <div className="w-full px-4 mb-6 sm:p-4">
                            <h1 className="mb-4 text-4xl font-medium text-gray-900 title-font sm:text-4xl">{content?.about?.title}</h1>
                            <div className="leading-relaxed">{content?.about.short}</div>
                        </div>
                        <div className="w-1/2 p-4 sm:w-1/2 lg:w-1/4">
                            <h2 className="text-2xl font-medium text-gray-900 title-font">{stats.project}</h2>
                            <p className="leading-relaxed">{I18n.t("screen.project")}</p>
                        </div>
                        <div className="w-1/2 p-4 sm:w-1/2 lg:w-1/4">
                            <h2 className="text-2xl font-medium text-gray-900 title-font">{stats.customer}</h2>
                            <p className="leading-relaxed">{I18n.t("screen.journey")}</p>
                        </div>
                        <div className="w-1/2 p-4 sm:w-1/2 lg:w-1/4">
                            <h2 className="text-2xl font-medium text-gray-900 title-font">{(((hours / 24) * 2) / 1000).toFixed(2)}K
                                <span className="absolute w-0 h-0 text-xs text-gray-600"><span className='relative left-1 top-3'>{I18n.t("screen.cups")}</span></span>
                            </h2>
                            <p className="leading-relaxed">{I18n.t("screen.coffee")}</p>
                        </div>
                        <div className="w-1/2 p-4 sm:w-1/2 lg:w-1/4">
                            <h2 className="text-2xl font-medium text-gray-900 title-font">{(hours / 1000).toFixed(2)}K
                                <span className="absolute w-0 h-0 text-xs text-gray-600"><span className='relative left-1 top-3'>{I18n.t("screen.hrs")}</span></span>
                            </h2>
                            <p className="leading-relaxed">{I18n.t("screen.experience")}</p>
                        </div>
                    </div>
                    <div className="w-full mt-6 overflow-hidden rounded-lg lg:w-1/2 sm:w-1/3 sm:mt-0">
                        <img className="object-contain object-center rounded" src={content?.about.imageUrl} alt="elevate" />
                    </div>
                </div>
            </section>

            <section className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap lg:grid lg:grid-cols-3">

                        {content?.reviews?.length > 0 && content.reviews.slice(0, 3).map((review, i) => (
                            <div key={`rw-${i}`} className="flex flex-col p-4 mb-6 lg:mb-0">
                                <div className="self-center align-middle"><img className="inline-block w-8 h-8 mb-8 text-gray-400 quote" src="/assets/icons/quote.svg" alt='quote' /></div>
                                <p className="flex flex-1 leading-relaxed text-center">{review.short}</p>
                                <div className="text-center">
                                    <span className="inline-block w-10 h-1 mt-6 mb-4 bg-blue-500 rounded"></span>
                                    <h2 className="text-sm font-medium tracking-wider text-gray-900 uppercase title-font">{review.name}</h2>
                                    <p className="text-gray-500">{review.role}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

            <section id="projects" className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                {content?.projects?.length > 0 && content.projects?.map((project, i) => (
                    <div key={`pr-${i}`} className="flex flex-col items-center px-5 py-24 md:flex-row">
                        <div className="w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0">
                            <picture>
                                <source srcSet={project.imageUrl.replace(".png", ".webp")} type="image/webp" />
                                <source srcSet={project.imageUrl} type="image/png" />
                                <img className='object-cover object-center bg-cover rounded-lg' alt="hero" src={project.imageUrl} />
                            </picture>
                        </div>
                        <div className="flex flex-col items-center text-center lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 md:items-start md:text-left">
                            <h1 className="mb-4 text-4xl font-medium text-gray-900 title-font sm:text-4xl">{project.title}</h1>
                            <p className="mb-8 leading-relaxed">{project.short}</p>
                        </div>
                    </div>
                ))}
            </section>


            <section className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap lg:grid lg:grid-cols-3">

                        {content?.reviews?.length > 0 && content.reviews.slice(3).map((review, i) => (
                            <div key={`rwx-${i}`} className="flex flex-col p-4 mb-6 lg:mb-0">
                                <div className="self-center align-middle"><img className="inline-block w-8 h-8 mb-8 text-gray-400 quote" src="/assets/icons/quote.svg" alt='quote' /></div>
                                <p className="flex flex-1 leading-relaxed text-center">{review.short}</p>
                                <div className="text-center">
                                    <span className="inline-block w-10 h-1 mt-6 mb-4 bg-blue-500 rounded"></span>
                                    <h2 className="text-sm font-medium tracking-wider text-gray-900 uppercase title-font">{review.name}</h2>
                                    <p className="text-gray-500">{review.role}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

            <ContactView />
            <Footer />
        </main>
    );
};